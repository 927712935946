:root { 
--white_A700_e5:#ffffffe5; 
--gray_901:#222222; 
--gray_900_11:#21212111; 
--gray_900:#212121; 
--black_900_2d:#0000002d; 
--indigo_300:#6e89e8; 
--indigo_301:#657dd3; 
--white_A700_cc:#ffffffcc; 
--black_900:#08070c; 
--gray_900_e5:#212121e5; 
--black_901:#0d0b0b; 
--white_A700_19:#ffffff19; 
--white_A700:#ffffff; 
--indigo_302:#6c84e2; 
}